import { useEffect, useContext, useRef } from "react"
import { Steps } from "intro.js-react"
import styles from "./CustomNode.module.css"
import "intro.js/introjs.css"
import { useQuery } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"

// Contexts
import { UserOnboardingTourContext } from "contexts/UserOnboardingTourContext"
import { SideDrawerContext } from "contexts/SideDrawerContext"
import { PersonalLinkCardContext } from "contexts/PersonalLinkCardContext"
import { ModalContext } from "contexts/ModalContext"
import { LightDarkModeContext } from "contexts/LightDarkModeContext"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

export const UserOnboardingTour = () => {
  const { sideDrawerState, setSideDrawerState } = useContext(SideDrawerContext)
  const { setModalState } = useContext(ModalContext)
  const { data } = useQuery(getActiveUserQuery)
  const { userOnboardingTourState, updateUserOnboardingTourState } = useContext(UserOnboardingTourContext)
  const { currentModeState } = useContext(LightDarkModeContext)
  const { setPersonalLinkCardState } = useContext(PersonalLinkCardContext)
  const stepsRef = useRef<Steps | null>(null)

  const isDarkModeEnabled = currentModeState.palette?.mode === "dark"

  const waitForElement = (
    selector: string,
    callback: (element: HTMLElement | null) => void,
    timeout: number = 2000
  ) => {
    const interval = 50
    let elapsed = 0

    const checkExist = setInterval(() => {
      const element = document.querySelector<HTMLElement>(selector)
      if (element) {
        clearInterval(checkExist)
        callback(element)
      } else if (elapsed >= timeout) {
        clearInterval(checkExist)
        console.warn(`Element ${selector} not found within timeout.`)
        callback(null) // Proceed even if not found
      }
      elapsed += interval
    }, interval)
  }

  const stepData = [
    {
      // idx 0
      title: "Welcome To The Tour!",
      intro: "This tour will briefly show you around Linkidex. Desktop users can navigate with the arrow keys.",
    },
    {
      // idx 1
      title: "Creating a Link",
      intro: "The 'New Link' button lets you add links to Linkidex",
      element: "#nav-add-link-button",
    },
    {
      // idx 2
      title: "Creating a Link",
      intro: "After clicking 'New link' this menu will pop up.",
    },
    {
      // idx 3
      title: "Creating a Link",
      intro:
        "When you create a Link, you must give it a URL. Title, Description, Category and Tags will help you stay organized, but they are all optional and you can always add them later.",
      element: "#outlined-adornment-link-url",
    },
    {
      // idx 4
      title: "Searching for Links",
      element: "#search-links-wrapper",
      intro:
        "This search bar lets you search links by title, url, categories and tags all at the same time. Just start typing and Linkidex will do the rest.",
    },
    {
      // idx 5
      title: "Searching for Links",
      intro: "Saved Links that satisfy your search query and filters appear here.",
      element: "#LinkSearchList",
      position: "right",
    },
    {
      // idx 6
      title: "Using Filters",
      intro:
        "Filters can be opened with the Icon at the top left of your screen, or by swiping from the left on mobile. ",
      element: "#search-filters-and-return-to-search",
    },
    {
      // idx 7
      title: "Using Filters",
      intro: "You can filter by category, tag, and organization",
    },
    {
      // idx 8
      title: "Managing Categories",
      intro: "Click here to view, edit, and delete your categories.",
      element: "#link-side-drawer-categories",
    },
    {
      // idx 9
      title: "Managing Tags",
      intro: "Click here to view, edit, and delete your tags.",
      element: "#link-side-drawer-tags",
    },
    {
      // idx 10
      title: "Filtering by Category and Tag",
      element: "#dnd-trees-personal",
      intro: "Click specific categories or tags to filter your links.",
    },
    {
      // idx 11
      title: "Removing Filters",
      element: "#side-drawer-clear-filters",
      intro: "'Clear Filters' removes all of the filters you have selected",
    },
    {
      // idx 12
      title: "Thats it!",
      element: "#open-settings-button",
      intro: "You can replay this tour from the account dropdown in the top right of your screen.",
    },
  ]

  const handleOpenSideDrawer = () => {
    setSideDrawerState({ ...sideDrawerState, isOpen: true })
  }

  const handleOpenPersonalCategoriesAndTagsOnSideDrawer = () => {
    setPersonalLinkCardState(true)
  }

  const handleCloseSideDrawer = () => {
    setSideDrawerState({ ...sideDrawerState, isOpen: false })
  }

  const handleOpenCreateLinkModal = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.CREATE_LINK })
  }

  const handleCloseAllModals = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  useEffect(() => {
    // when current user changes (such as during login or logout) reset UserOnboardingTourState
    updateUserOnboardingTourState({
      hasSeenWelcome: false,
      isOpen: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.activeUser?.id])

  return (
    <>
      <Steps
        onBeforeChange={async (nextStepIdx) => {
          return new Promise((resolve) => {
            if (nextStepIdx === 1) {
              handleCloseAllModals()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 2) {
              handleOpenCreateLinkModal()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 3) {
              handleOpenCreateLinkModal()
              waitForElement("#outlined-adornment-link-url", (element) => {
                if (element) {
                  stepsRef?.current?.updateStepElement(nextStepIdx)
                }
                resolve()
              })
              setInterval(resolve, 250)
            } else if (nextStepIdx === 4) {
              handleCloseAllModals()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 5) {
              handleCloseSideDrawer()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 6) {
              setInterval(resolve, 250)
            } else if (nextStepIdx === 7) {
              setInterval(resolve, 250)
              handleOpenSideDrawer()
              handleOpenPersonalCategoriesAndTagsOnSideDrawer()
            } else if (nextStepIdx === 8) {
              setInterval(resolve, 250)
              waitForElement("#link-side-drawer-categories", (element) => {
                if (element) {
                  stepsRef?.current?.updateStepElement(nextStepIdx)
                }
                resolve()
              })
            } else if (nextStepIdx === 9) {
              setInterval(resolve, 250)
              handleOpenPersonalCategoriesAndTagsOnSideDrawer()
              waitForElement("#link-side-drawer-tags", (element) => {
                if (element) {
                  stepsRef?.current?.updateStepElement(nextStepIdx)
                }
                resolve()
              })
            } else if (nextStepIdx === 10) {
              setInterval(resolve, 250)
              waitForElement("#dnd-trees-personal", (element) => {
                if (element) {
                  stepsRef?.current?.updateStepElement(nextStepIdx)
                }
                resolve()
              })
            } else if (nextStepIdx === 11) {
              handleOpenSideDrawer()
              setInterval(resolve, 250)
              waitForElement("#side-drawer-clear-filters", (element) => {
                if (element) {
                  stepsRef?.current?.updateStepElement(nextStepIdx)
                }
                resolve()
              })
            } else if (nextStepIdx === 12) {
              handleCloseSideDrawer()
              setInterval(resolve, 250)
            } else {
              setInterval(resolve, 0)
            }
          })
        }}
        enabled={userOnboardingTourState.isOpen}
        steps={stepData}
        ref={stepsRef}
        initialStep={0}
        options={{
          tooltipClass: isDarkModeEnabled ? styles.tooltipClassDark : styles.tooltipClassLight,
          highlightClass: styles.highlightClass,
        }}
        onExit={() => {
          updateUserOnboardingTourState({
            hasSeenWelcome: true,
            isOpen: false,
          })
        }}
      />
    </>
  )
}
