import { useEffect, useState } from "react"
import Fuse from "fuse.js"
import { format } from "date-fns"
import { NodeModel } from "@minoru/react-dnd-treeview"
import { useQuery, useApolloClient } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"

// interfaces
import { TagInterface } from "interfaces/Tags"
import { FolderInterface } from "interfaces/Folders"
import { CategoryInterface } from "interfaces/Category"
import { PinInterface } from "interfaces/Pin"
import { FOLDER, CATEGORY, TAG } from "constants/Global"

// mui
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"

const emailRegex = /^[^@]+@[^@]+\.[^@]+$/

export const isValidEmail = (email: string) => {
  return email.match(emailRegex)
}

export const supportsWebauthn = () => {
  return !!window.PublicKeyCredential
}

export const isSameTag = (a: TagInterface, b: TagInterface) => a.title === b.title && a.id === b.id

export const tagsOnlyInFirstList = (firstTagList: TagInterface[], secondTagList: TagInterface[]) =>
  firstTagList.filter((leftValue: any) => !secondTagList.some((rightValue: any) => isSameTag(leftValue, rightValue)))

export const createFuseFuzzySearch = <T1,>(data: T1[], options: {}, searchText: string) => {
  // TODO split this up so we don't needlessly create new Fuses all the time
  const fuse = new Fuse<T1>(data, options)
  if (searchText) {
    return fuse.search(searchText)
  } else {
    // returns all results in the same format fuse does if there is no searchText, thus fuse is returning 0 results
    return data.map((item: T1, refIndex) => ({
      item,
      refIndex,
      matches: [],
      score: 1,
    }))
  }
}

export const formatDateTime = (date: string) => {
  const dateInt = Date.parse(date)
  //                      12:31 pm 09/26/2022 GMT-7
  return format(dateInt, "hh:mm aaa MM/dd/yyyy OOOO")
}

export const maxStringLength = (string: string, maxLength: number) => {
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + "..."
  }
  return string
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    if (process.env.REACT_APP_BUILD_TYPE !== "chrome") {
      // the above line prevents us from setting the resize event on the window for
      // extensions. Firefox was constantly resizing for no reason, even though it is
      // not possible to resize extension
      window.addEventListener("resize", handleResize)
    }
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

export const debounceFunction = (timeoutRef: any, func: () => any, time: number) => {
  clearTimeout(timeoutRef.current)
  timeoutRef.current = setTimeout(() => {
    func()
  }, time)
}

interface countOfSelectedDrawerTypesInterface {
  [key: number]: boolean
}

export const countOfSelectedDrawerTypes = (selectedTypes: countOfSelectedDrawerTypesInterface) => {
  let countOfCategories = 0
  Object.values(selectedTypes).map((value) => {
    if (value === true) {
      countOfCategories += 1
    }
    return null
  })
  return countOfCategories
}

export const useIsMobile = () => {
  // returns true if the screen size is small
  // return false
  const theme = useTheme()
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"))
  if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
    // browser extension is not mobile
    return false
  }
  return isMobile
}

export const useIsExtension = () => {
  // returns true if the build type is chrome or firefox
  if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
    return true
  }
}

export const useIsLoggedIn = () => {
  const { data, error } = useQuery(getActiveUserQuery)
  const client = useApolloClient()
  if (error) {
    // add a fake user to Cache, else app will keep trying to find a user every render because no user exists
    client.writeQuery({
      query: getActiveUserQuery,
      data: {
        activeUser: {
          id: null,
          email: null,
          emailVerified: null,
          secondFactorEnabled: null,
          isPro: null,
          hasActiveSubscription: null,
        },
      },
    })
    return false
  }
  return !!data?.activeUser?.email
}

export const buildFolderData = (
  rawFolderData: FolderInterface[],
  dataType: typeof CATEGORY | typeof TAG,
  isNotMobile: boolean,
  organizationId?: string
): NodeModel<{
  contentType: typeof FOLDER | typeof CATEGORY | typeof TAG
}>[] => {
  let folderData = rawFolderData.filter((folder: FolderInterface) => {
    return folder.folderType === dataType && folder.organization?.id == organizationId
  })

  return folderData.map((folder: FolderInterface) => ({
    id: `${FOLDER}-${folder.id}`,
    parent: folder.parentFolderId ? `${FOLDER}-${folder.parentFolderId}` : 0,
    text: folder.title,
    droppable: true,
    data: {
      contentId: folder.id,
      contentType: folder.folderType,
      contentTitle: folder.title,
      contentDescription: folder.description,
      isNotMobile: isNotMobile,
      parentFolderId: folder.parentFolderId,
      parentFolderTitle: folder.parentFolderTitle,
      organization: folder.organization,
    },
  }))
}

export const buildCategoryData = (
  rawCategoryData: CategoryInterface[],
  isNotMobile: boolean,
  organizationId: string | undefined,
  isItemChecked: (id: number) => boolean | undefined,
  isUserOnLinksPage: boolean,
  toggleItem: (id: number) => void
) => {
  let categoryData = rawCategoryData.filter((category: CategoryInterface) => {
    return category.organization?.id == organizationId
  })

  return categoryData.map((category: CategoryInterface) => ({
    id: `${CATEGORY}-${category.id}`,
    parent: category.folder?.id ? `${FOLDER}-${category.folder?.id}` : 0,
    text: category.title,
    droppable: false,
    data: {
      contentType: CATEGORY,
      contentId: category.id,
      contentTitle: category.title,
      contentDescription: category.description,
      isItemChecked: isItemChecked,
      isUserOnLinksPage: isUserOnLinksPage,
      toggleItem: toggleItem,
      isNotMobile: isNotMobile,
      folderId: category.folder?.id,
      folderTitle: category.folder?.title,
      organization: category.organization,
      pin: category.pin,
    },
  }))
}
export const buildTagData = (
  rawTagData: TagInterface[],
  isNotMobile: boolean,
  organizationId: string | undefined,
  isItemChecked: (id: number) => boolean | undefined,
  isUserOnLinksPage: boolean,
  toggleItem: (id: number) => void
) => {
  let tagData = rawTagData.filter((tag: TagInterface) => {
    return tag.organization?.id == organizationId
  })

  return tagData.map((tag: TagInterface) => ({
    id: `${TAG}-${tag.id}`,
    parent: tag.folder?.id ? `${FOLDER}-${tag.folder?.id}` : 0,
    text: tag.title,
    droppable: false,
    data: {
      contentType: TAG,
      contentId: tag.id,
      contentTitle: tag.title,
      contentDescription: tag.description,
      isItemChecked: isItemChecked,
      isUserOnLinksPage: isUserOnLinksPage,
      toggleItem: toggleItem,
      isNotMobile: isNotMobile,
      folderId: tag.folder?.id,
      folderTitle: tag.folder?.title,
      organization: tag.organization,
      pin: tag.pin,
    },
  }))
}

export const sortByText = (a: string, b: string) => {
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1
  }
  return 0
}

export const sortTreeDataByTextAndPin = (
  a: NodeModel<{
    pin: PinInterface
    contentType: typeof FOLDER | typeof CATEGORY | typeof TAG
  }>,
  b: NodeModel<{
    pin: PinInterface
    contentType: typeof FOLDER | typeof CATEGORY | typeof TAG
  }>
) => {
  // given tree data, sort tree data by if has a pin, and then by its name

  if (a.data && a.data.pin && (!b.data || !b.data.pin)) {
    return -1
  }

  if (b.data && b.data.pin && (!a.data || !a.data.pin)) {
    return 1
  }

  if (a.text.toLowerCase() < b.text.toLowerCase()) {
    return -1
  }
  if (a.text.toLowerCase() > b.text.toLowerCase()) {
    return 1
  }
  return 0
}

export const isValidUrl = (str: string | URL) => {
  // checks if a given URL is valid
  try {
    const newUrl = new URL(str)
    return newUrl.protocol === "http:" || newUrl.protocol === "https:"
  } catch (err) {
    return false
  }
}

export const parseGraphQlError = (graphQlErrorResponse: any) => {
  return graphQlErrorResponse.message
}
