import { useEffect, useContext, useState, useRef, memo } from "react"
import { FixedSizeList, ListChildComponentProps } from "react-window"
import Fuse from "fuse.js"

// Components
import { SearchRow } from "./SearchRow"
import Box from "@mui/material/Box"

// Utils
import { useWindowDimensions, useIsMobile, useIsExtension } from "Utils/Utils"
import { SEARCH_ROW_MOBILE, SEARCH_ROW_DESKTOP } from "constants/Global"
import { LinkInterface } from "interfaces/Link"

// Contexts
import { AlertBarContext } from "contexts/AlertBarContext"

interface LinkSearchListProps {
  filteredResults: Fuse.FuseResult<LinkInterface>[]
}

export const LinkSearchList = memo((props: LinkSearchListProps) => {
  const { filteredResults } = props
  const { height } = useWindowDimensions()
  const isMobile = useIsMobile()
  const isExtension = useIsExtension()
  const { alertBarState } = useContext(AlertBarContext)

  const targetRef = useRef<HTMLDivElement | null>(null) // Reference to the target element
  const [offsetTop, setOffsetTop] = useState<number>(0) // Store the pixel value

  // Function to calculate the offset from the top
  const calculateOffset = () => {
    if (targetRef.current) {
      const rect: DOMRect = targetRef.current.getBoundingClientRect()
      setOffsetTop(rect.top) // 'top' gives the distance from the viewport's top
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      calculateOffset()
    }, 200)

    // we need to delay the calculateOffset because the AlertBar closing animation takes time to finish.
    // we will calculate the wrong size if we call calculateOffset while the close animation is still playing
    return () => {
      clearTimeout(handler)
    }
  }, [alertBarState.isOpen])

  useEffect(() => {
    // Calculate the offset on component mount
    calculateOffset()

    // Update the offset when the window is resized or scrolled
    window.addEventListener("resize", calculateOffset)
    window.addEventListener("scroll", calculateOffset)

    // Cleanup listeners when the component unmounts
    return () => {
      window.removeEventListener("resize", calculateOffset)
      window.removeEventListener("scroll", calculateOffset)
    }
  }, [])

  const itemSize = () => {
    if (isMobile || isExtension) {
      return SEARCH_ROW_MOBILE
    } else {
      return SEARCH_ROW_DESKTOP
    }
  }

  return (
    <Box id="LinkSearchList" ref={targetRef}>
      <FixedSizeList
        height={height - offsetTop}
        width="100%"
        itemSize={itemSize()}
        itemCount={filteredResults.length}
        itemData={{ filteredResults }}
        overscanCount={0}
      >
        {(
          args: ListChildComponentProps<{
            filteredResults: Fuse.FuseResult<LinkInterface>[]
          }>
        ) => SearchRow(args)}
      </FixedSizeList>
    </Box>
  )
})
