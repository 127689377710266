import { useContext, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { ALERT_BAR_TYPES } from "./alertBarTypes"
import { getActiveUserQuery } from "queries/queries"
import { AlertBarContext } from "contexts/AlertBarContext"
import { ResendEmailVerification } from "components/ResendEmailVerification"

// MUI
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

export const EmailAlertBar = () => {
  const { loading, data } = useQuery(getActiveUserQuery)
  const { alertBarState, setAlertBarState } = useContext(AlertBarContext)

  useEffect(() => {
    if (loading) {
      return
    } else if (data?.activeUser?.emailVerified === false || data?.activeUser?.emailVerified === null) {
      setAlertBarState({ isOpen: true, alertBarType: ALERT_BAR_TYPES.VERIFY_EMAIL })
    } else {
      setAlertBarState({ isOpen: false, alertBarType: ALERT_BAR_TYPES.VERIFY_EMAIL })
    }
  }, [data?.activeUser?.emailVerified, loading, setAlertBarState])

  return (
    <>
      <Collapse in={alertBarState.isOpen} sx={{ px: { xs: 2, md: 6 } }}>
        <Alert
          sx={{ mt: 1 }}
          variant="outlined"
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertBarState({ isOpen: false, alertBarType: "" })
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography>
            Email unverified, please check your email to verify your account.
            <ResendEmailVerification />
          </Typography>
        </Alert>
      </Collapse>
    </>
  )
}
