import React, { useState, useContext, useEffect, useRef } from "react"
import { useMutation } from "@apollo/client"
import { submitFreeAccessCodeMutation } from "queries/queries"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"

// Contexts
import { ModalContext } from "../../contexts/ModalContext"
import { SnackBarContext } from "../../contexts/SnackBarContext"

// Utils
import { debounceFunction, parseGraphQlError } from "Utils/Utils"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { GraphQlErrorResponse } from "interfaces/Apis"
// Components
import { InputTextField } from "components/InputTextField"
import { CloseModalButton } from "components/Modal/CloseModalButton"

// TYPES
import { DEBOUNCE_TIME } from "constants/Global"

export const FreeAccessModal = () => {
  const timeout = useRef<any>()
  const [submitCodeError, setSubmitCodeError] = useState<boolean>(false)
  const [submitCodeErrorText, setSubmitCodeErrorText] = useState<string>(" ")
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [inputCode, setInputCode] = useState<string>("")
  const { setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const [submitFreeAccessCode] = useMutation(submitFreeAccessCodeMutation)

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const debouncedHandleSubmitCode = () => {
    debounceFunction(timeout, handleUpdateEmail, DEBOUNCE_TIME)
  }

  useEffect(() => {
    const listener = (event: any) => {
      if ((event.code === "Enter" || event.code === "NumpadEnter") && !isSubmitDisabled) {
        event.preventDefault()
        debouncedHandleSubmitCode()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitDisabled, inputCode])

  useEffect(() => {
    if (inputCode.length < 4) {
      setIsSubmitDisabled(true)
    } else {
      setIsSubmitDisabled(false)
    }
  }, [inputCode])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputCode(event.target.value)
  }

  const handleUpdateEmail = () => {
    setSubmitCodeError(false)
    setSubmitCodeErrorText(" ")
    submitFreeAccessCode({
      variables: {
        code: inputCode,
      },
    }).then(
      (res: object) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Free Access has been granted!",
        })
        closeModal()
      },
      (res: GraphQlErrorResponse) => {
        setSubmitCodeError(true)
        if (res.message) {
          setSubmitCodeErrorText(res.message)
        } else {
          setSubmitCodeErrorText("This code was invalid.")
        }
      }
    )
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 2 }}
        aria-live="polite"
        aria-label="Input Free Access Code Form"
      >
        Input Code
      </Typography>

      <Typography variant="body1" sx={{ my: 2 }} aria-live="polite" aria-label="Input Free Access Code Form">
        Codes can be purchased, won, or given away in various different ways. A code is not required to use Linkidex.
      </Typography>

      <InputTextField
        name="code"
        helperText="Code"
        handleChange={handleChange}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={inputCode}
        error={submitCodeError}
        errorText=""
      />

      <FormControl error={submitCodeError}>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          onClick={() => debouncedHandleSubmitCode()}
          disabled={isSubmitDisabled}
          aria-label="submit email update"
        >
          Submit
        </Button>
        <FormHelperText aria-live="polite" aria-label={submitCodeErrorText}>
          {submitCodeErrorText}
        </FormHelperText>
      </FormControl>
    </>
  )
}
