import * as React from "react"
import { createContext, useState } from "react"

// Utils
import { AlertBarStateInterface } from "../interfaces/AlertBar"

interface AlertBarContextInterface {
  alertBarState: AlertBarStateInterface
  setAlertBarState: React.Dispatch<React.SetStateAction<AlertBarStateInterface>>
}

const defaultState = { isOpen: false, alertBarType: "" }

export const AlertBarContext = createContext<AlertBarContextInterface>({
  alertBarState: defaultState,
  setAlertBarState: () => {},
})

export const AlertBarContextProvider = (props: { children: React.ReactNode }) => {
  const [alertBarState, setAlertBarState] = useState(defaultState)

  return (
    <AlertBarContext.Provider
      value={{
        alertBarState,
        setAlertBarState,
      }}
    >
      {props.children}
    </AlertBarContext.Provider>
  )
}
