import * as React from "react"
import { createContext, useState, useEffect, useCallback } from "react"
import { DEFAULT_DRAWER_WIDTH } from "constants/Global"
import { useIsMobile } from "Utils/Utils"

interface SideDrawerInterface {
  isOpen: boolean
  width: number
}

interface SideDrawerContextInterface {
  sideDrawerState: SideDrawerInterface
  setSideDrawerState: React.Dispatch<React.SetStateAction<SideDrawerInterface>>
}

const determineDefaultState = (isMobile?: boolean) => {
  if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
    chrome.storage.local.get(["sideDrawerStateChrome"], (result) => {
      if (result?.sideDrawerStateChrome) {
        return result.sideDrawerStateChrome
      } else {
        return { isOpen: false, width: DEFAULT_DRAWER_WIDTH }
      }
    })
    return { isOpen: false, width: DEFAULT_DRAWER_WIDTH }
  } else {
    return { isOpen: isMobile ? false : true, width: DEFAULT_DRAWER_WIDTH }
  }
}

export const SideDrawerContext = createContext<SideDrawerContextInterface>({
  sideDrawerState: determineDefaultState(),
  setSideDrawerState: () => {},
})

export const SideDrawerContextProvider = (props: { children: React.ReactNode }) => {
  const isMobile = useIsMobile()
  const [sideDrawerState, setSideDrawerStatePrivate] = useState(determineDefaultState(isMobile))

  // Load state from chrome.storage.local on mount
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get(["sideDrawerStateChrome"], (result) => {
        if (result?.sideDrawerStateChrome) {
          setSideDrawerStatePrivate(result.sideDrawerStateChrome) // Initialize context state
        }
      })
    }
  }, [])

  // Save state to chrome.storage.local on state change
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ sideDrawerStateChrome: sideDrawerState })
    }
  }, [sideDrawerState])

  // Update state
  const setSideDrawerState = useCallback((newState) => {
    setSideDrawerStatePrivate(newState)
  }, [])

  return (
    <SideDrawerContext.Provider
      value={{
        sideDrawerState,
        setSideDrawerState,
      }}
    >
      {props.children}
    </SideDrawerContext.Provider>
  )
}
