import { useContext } from "react"
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { persistCache } from "apollo3-cache-persist"
import { NavigateFunction, useNavigate, useLocation } from "react-router-dom"

// Constants
import { CURRENT_VERSION_KEY } from "constants/Global"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

// Components
import { Navbar } from "./components/Navbar"
import { ActivePage } from "./components/ActivePage"
import { Modal } from "./components/Modal"
import { SnackBar } from "./components/SnackBar"
import { SideDrawerWrapper } from "components/SideDrawerWrapper"

// Contexts
import { SelectedCategoriesContext } from "contexts/SelectedCategoriesContext"
import { SelectedTagsContext } from "contexts/SelectedTagsContext"
import { SideDrawerContext } from "contexts/SideDrawerContext"
import { UserOnboardingTourContext } from "contexts/UserOnboardingTourContext"
import { PersonalLinkCardContext } from "contexts/PersonalLinkCardContext"
import { ModalContext } from "contexts/ModalContext"
import { PersonalLinksSelectedContext } from "contexts/PersonalLinksSelectedContext"
import { SelectedOrganizationsContext } from "contexts/SelectedOrganizationsContext"
import { SnackBarContext } from "contexts/SnackBarContext"
import { FavoritesSelectedContext } from "contexts/FavoritesSelectedContext"
import { IsStrictSearchContext } from "contexts/IsStrictSearchContext"

// Utils
import { ChromeStorageWrapper } from "./Utils/ChromeStorageWrapper"

interface CustomNetworkError extends Error {
  result?: any // Extend network error to include the result property
  statusCode?: number
}

// todo david this is janky
const loggedInUrls = ["/account", "/links"]

const createErrorLink = (navigate: NavigateFunction, locationPath: string, handleLoggedOutUser: () => void) =>
  onError(({ operation, networkError, forward }) => {
    if (process.env.REACT_APP_BUILD_TYPE !== "chrome") {
      localStorage.setItem(CURRENT_VERSION_KEY, operation.getContext().response.headers.get("linkidex-fe-v"))
      const event = new Event("itemInserted")
      document.dispatchEvent(event)
    }
    if (networkError && operation.getContext().response.status === 401) {
      const customNetworkError = networkError as CustomNetworkError
      if (customNetworkError?.result?.error === "LoginRequired") {
        if (process.env.REACT_APP_BUILD_TYPE === "chrome" && locationPath !== "/") {
          // user is logged out while using extension
          navigate("/")
          cache.evict({ id: "ROOT_QUERY" })
          cache.evict({ id: "ROOT_MUTATION" })
          cache.evict({ id: "ROOT_SUBSCRIPTION" })
          cache.gc() // Garbage collection to remove all references
          handleLoggedOutUser()
        } else if (
          process.env.REACT_APP_BUILD_TYPE !== "chrome" &&
          locationPath !== "/login" &&
          loggedInUrls.includes(locationPath)
        ) {
          // user is logged out while using web
          navigate("/login")
          cache.evict({ id: "ROOT_QUERY" })
          cache.evict({ id: "ROOT_MUTATION" })
          cache.evict({ id: "ROOT_SUBSCRIPTION" })
          cache.gc() // Garbage collection to remove all references
          handleLoggedOutUser()
        }
      }
    }
  })

const createApolloClient = (navigate: NavigateFunction, locationPath: string, handleLoggedOutUser: () => void) => {
  // const errorLink = createErrorLink(navigate)
  const client = new ApolloClient({
    cache: cache,
    link: ApolloLink.from([
      createErrorLink(navigate, locationPath, handleLoggedOutUser),
      // place any other links before the line below
      clientVersionAfterware.concat(httpLink),
    ]),
  })
  return client
}

const httpLink = createHttpLink({
  uri: `${process.env.PUBLIC_URL}/graphql/`,
  credentials: "include",
})

const clientVersionAfterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response: any) => {
    const {
      response: { headers },
    } = operation.getContext()
    // note you MUST explicitly get the header by name
    localStorage.setItem(CURRENT_VERSION_KEY, headers.get("linkidex-fe-v"))
    const event = new Event("itemInserted")
    document.dispatchEvent(event)
    return response
  })
)

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        links: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          // Concatenate the incoming list items with
          // the existing list items.
          // @ts-ignore
          merge(existing, incoming, { args: { offset = 0 } }) {
            // Slicing is necessary because the existing data is
            // immutable, and frozen in development.
            // @ts-ignore
            const merged = existing ? existing.slice(0) : []
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i]
            }
            return merged
          },
        },
        userOrganizations: {
          // replace cache with data from backend, do not merge
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
  },
})

if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
  await persistCache({
    cache,
    storage: new ChromeStorageWrapper("local"),
  })
}

export function ApolloWrapper() {
  const navigate = useNavigate()
  const location = useLocation()
  const { setSelectedCategoriesState } = useContext(SelectedCategoriesContext)
  const { setSelectedTagsState } = useContext(SelectedTagsContext)
  const { sideDrawerState, setSideDrawerState } = useContext(SideDrawerContext)
  const { updateUserOnboardingTourState } = useContext(UserOnboardingTourContext)
  const { setPersonalLinkCardState } = useContext(PersonalLinkCardContext)
  const { setModalState } = useContext(ModalContext)
  const { setPersonalLinksSelectedState } = useContext(PersonalLinksSelectedContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const { setSelectedOrganizationsState } = useContext(SelectedOrganizationsContext)
  const { setFavoritesSelectedState } = useContext(FavoritesSelectedContext)
  const { setIsStrictSearch } = useContext(IsStrictSearchContext)

  const handleLoggedOutUser = () => {
    setSelectedCategoriesState({})
    setSelectedTagsState({})
    setSideDrawerState({ ...sideDrawerState, isOpen: false })
    setModalState({ isOpen: false, modalType: "" })
    setPersonalLinksSelectedState(false)
    setSnackBarState({ isOpen: false, snackBarType: SNACK_BAR_TYPES.SUCCESS, message: "" })
    setSelectedOrganizationsState({})
    updateUserOnboardingTourState({ hasSeenWelcome: false, isOpen: false })
    setFavoritesSelectedState(false)
    setPersonalLinkCardState(false)
    setIsStrictSearch(false)
  }

  const client = createApolloClient(navigate, location.pathname, handleLoggedOutUser)
  return (
    <>
      <ApolloProvider client={client}>
        <div className="App">
          <Navbar />
          <Modal />
          <SnackBar />
          <SideDrawerWrapper />
          <ActivePage />
        </div>
      </ApolloProvider>
    </>
  )
}
