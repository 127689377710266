export enum MODAL_TYPES {
  // account
  SIGN_UP = "SIGN_UP",
  UPDATE_EMAIL = "UPDATE_EMAIL",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  FREE_ACCESS = "FREE_ACCESS",
  // links
  CREATE_LINK = "CREATE_LINK",
  EDIT_LINK = "EDIT_LINK",
  DELETE_LINK = "DELETE_LINK",
  // categories
  CREATE_COLLECTION = "CREATE_COLLECTION",
  EDIT_COLLECTION = "EDIT_COLLECTION",
  DELETE_COLLECTION = "DELETE_COLLECTION",
  // tags
  CREATE_TAG = "CREATE_TAG",
  EDIT_TAG = "EDIT_TAG",
  DELETE_TAG = "DELETE_TAG",
  // folders
  CREATE_FOLDER = "CREATE_FOLDER",
  EDIT_FOLDER = "EDIT_FOLDER",
  DELETE_FOLDER = "DELETE_FOLDER",
  // security
  SECOND_FACTOR = "SECOND_FACTOR",
  WEBAUTHN_FACTOR = "WEBAUTHN_FACTOR",
  MANAGE_TWO_FACTOR = "MANAGE_TWO_FACTOR",
  MANAGE_BACKUP_CODES = "MANAGE_BACKUP_CODES",
  LOST_AUTHENTICATOR = "LOST_AUTHENTICATOR",
  MANAGE_WEBAUTHN = "MANAGE_WEBAUTHN",
  // orgs
  CREATE_ORGANIZATION = "CREATE_ORGANIZATION",
  LEAVE_ORGANIZATION = "LEAVE_ORGANIZATION",
  MANAGE_USER_ORGANIZATION_PERMISSIONS = "MANAGE_USER_ORGANIZATION_PERMISSIONS",
  REMOVE_USER_FROM_ORGANIZATION = "REMOVE_USER_FROM_ORGANIZATION",
  CREATE_ORGANIZATION_INVITE = "CREATE_ORGANIZATION_INVITE",
  ORGANIZATION_ROLES_DESCRIPTION = "ORGANIZATION_ROLES_DESCRIPTION",
  ORGANIZATION_STATE_DESCRIPTION = "ORGANIZATION_STATE_DESCRIPTION",
  // other
  IPHONE_DOWNLOAD_SCREENSHOT = "IPHONE_DOWNLOAD_SCREENSHOT",
  WELCOME_TO_LINKIDEX = "WELCOME_TO_LINKIDEX",
  IMPORT_BOOKMARKS_HOW_TO = "IMPORT_BOOKMARKS_HOW_TO",
  USE_CATEGORIES_AND_TAGS_FOR_AI_SUGGEST_HOW_TO = "USE_CATEGORIES_AND_TAGS_FOR_AI_SUGGEST_HOW_TO",
}
